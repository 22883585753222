import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Weiden: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Weiden,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Weiden"
          d="M174.1,672.8c0.4-1.2,1.4-2.4,2.5-3c-1-2.9-1.9-6.7-3-9.5h-0.3l-8-0.1c0.1-7.3,0.4-17.9,0.4-25.1l-9.6-0.3 c-6.6-1.4-17,0.3-23.8,1.1c0-0.7-0.3-3.5,0.2-4.1c7.8-1.6,16.7-2.9,24.6-4c-0.1-0.5-0.3-1.5-0.4-2l0,0l95.2-12.8 c1,53.4,5.6,43.4-16.9,93h-4.1c2.9-8.3,1.7-18.9-8.3-21.1c-15.2-5.1-32.7-9-48.7-10C173.9,674.3,174,673.4,174.1,672.8z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 190.5267 653.8533)"
        >
          Weiden
        </text>
      </g>
    </>
  );
});
